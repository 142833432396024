import React, { Component } from 'react';
import {connect} from "react-redux";

import { musicChange } from '../../actions/navActions';

import Sound from "react-sound";

class BackgroundMusic extends Component {

    constructor(props) {
        super(props);
        this.onFinishedTrack = this.onFinishedTrack.bind(this);
    }

    render() {
        /*
        for quick/simple close of phase 1 of this work, we've moved from the trigger-based approach coded up here
        to just having a single track playing on loop throughout. Rather than undoing all this code, I'm just detaching the
        callbacks etc., and leaving the complex logic in place for when we do pick it up

        For posterity, the issue was that the looping of the audio via soundmanager2/react-sound gave a short stutter/delay,
        which was noticeable when looping short 10-sec sequences, as we'd originally planned. Seems like this is endemic to
        soundmanager2, the solution would be to do a programmatic fade/overlap of the queued tracks, either using the in-play
        callback of this library, or html5 audio directly

        https://stackoverflow.com/questions/21022617/gap-less-playback-through-the-soundmanager
        https://www.npmjs.com/package/react-redux-webaudio
         */
        let hasM = this.hasMusic();
        let currT = (hasM) ? this.currentTrack() : "n/a";
        let looping = this.useLoop();
        console.log("MUSIC :: render", hasM, currT, this.props.musicOn);
        return (<>{ hasM && <Sound
            url={ "audio/Lab Scene Full.mp3" }
            playStatus={(this.props.musicOn) ? Sound.status.PLAYING : Sound.status.STOPPED}
            loop={true}
            autoLoad={true}
            onFinishedPlaying={this.onFinishedTrack}
        />}</>);
    }

    hasMusic() {
        let music = this.props.backgroundMusic;
        let hasMusic = (music.tracks || []).length > music.current;
        console.log("MUSIC :: hasMusic?", music, hasMusic);
        return hasMusic;
    }

    currentTrack() {
        let music = this.props.backgroundMusic;
        return "audio/"+music.tracks[music.current]+".mp3";
    }

    useLoop() {
        let music = this.props.backgroundMusic;
        let ntracks = (music.tracks || []).length;
        let lastTrack = (ntracks > 0) ? music.tracks[music.tracks.length - 1] : "";
        let doLoop = (ntracks === music.current+1) && lastTrack.endsWith("loop");
        console.log("MUSIC :: use loop?", ntracks, lastTrack, doLoop);
        return doLoop;
    }

    onFinishedTrack() {
        let music = JSON.parse(JSON.stringify(this.props.backgroundMusic));
        console.log("MUSIC track finished", music);
        if (!music.loop || ((music.tracks.length -1) > music.current)) {
            music.current++;
        }
        this.props.dispatch(musicChange(music));
    }
}
const mapStateToProps = (state) => {
    return {
        backgroundMusic: state.backgroundMusic,
        musicOn: state.musicOn,
    };
}
export default connect(mapStateToProps)(BackgroundMusic);
