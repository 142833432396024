import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';

const ThemedHelpMessage = withStyles({
    paper: {
        color: "black",
        fontSize: "1.5em",
        padding: "15px",
        maxWidth: 360,
        borderRadius: "10px",
    },
})(Popover);

export default ThemedHelpMessage;