import React, {Component} from "react";
import {connect} from "react-redux";

import DataVisRow from './DataVisRow';
import { diff } from '../../model/Calculations';
import { historyItem } from "../../actions/experimentActions";

class DataVis extends Component {

    componentWillReceiveProps(nextProps, nextContext) {
        let similarity = diff(nextProps.viewedData);
        if (similarity !== null) {
            this.props.dispatch(historyItem(nextProps.viewedData, nextProps.dataType, similarity, this.props.environment));
        }
    }

    render() {
        let difference = diff(this.props.viewedData);
        let dtype = (this.props.dataType === "genes") ? "genetic sequence" : "epigenetic modifications";
        return (
            <div class='labSequencingTool' style={{
                position: "absolute",
                top: 410,
                left: 120,
            }}>
                <DataVisRow index={0} endDrop={this.props.endDrop}/>
                <DataVisRow index={1} endDrop={this.props.endDrop}/>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        viewedData: state.viewedData,
        dataType: state.dataType,
        environment: state.environment
    };
};
export default connect(mapStateToProps)(DataVis);
