import states from '../model/States';

export function section(state = [], action) {
    switch (action.type) {
        case 'SECTION_CHANGED':
            return action.section;
        default:
            return state;
    }
}

export function errors(state = [], action) {
    switch (action.type) {
        case 'ERROR_OCCURRED':
            if (action.additive) {
                let currentErrors = JSON.parse(JSON.stringify(state));
                action.errors.concat(currentErrors);
                return currentErrors;
            } else {
                return action.errors;
            }
        default:
            return state;
    }
}

export function backgroundMusic(state = [], action) {
    switch (action.type) {
        case 'SECTION_CHANGED':
            let s = action.section;
            let newMusic = JSON.parse(JSON.stringify(state));
            if (newMusic.tracks.length > newMusic.current) {
                newMusic.tracks = newMusic.tracks.slice(0, newMusic.current+1);
            }
            let sm = states.music[s];
            let tracksToAdd = sm.tracks;
            if (newMusic.tracks[newMusic.tracks.length - 1] === tracksToAdd[0]){
                tracksToAdd.pop();
            }
            if (tracksToAdd.length > 0) {
                newMusic.tracks = newMusic.tracks.concat(tracksToAdd);
            }
            newMusic.loop = sm.loop;
            console.log("MUSIC :: section change", s, newMusic);
            return newMusic;
        case 'MUSIC_UPDATED':
            return action.music;
        default:
            return state;
    }
}

export function musicOn(state = [], action) {
    switch (action.type) {
        case 'VOLUME_CONTROL':
            localStorage.setItem("VOLUME_CONTROL", action.musicOn);
            return action.musicOn;
        default:
            return state;
    }
}

export function help(state = [], action) {
    switch (action.type) {
        case 'HELP_FOR_SECTION':
            return states.help[action.section] || [];
        default:
            return state;
    }
}

export function slide(state = [], action) {
    switch (action.type) {
        case 'SLIDE_CHANGE':
            let maxSlide = (state > action.slide) ? state : action.slide;
            console.log("slides, current", action.slide, "max", maxSlide);
            return maxSlide;
        default:
            return state;
    }

}
