export function sectionChanged(newSection) {
    return {
        type: 'SECTION_CHANGED',
        section: newSection
    };
}

export function errors(errorMessages) {
    return {
        type: 'ERROR_OCCURRED',
        additive: false,
        errors: arr(errorMessages)
    }
}

export function addErrors(errorMessages) {
    return {
        type: 'ERROR_OCCURRED',
        additive: true,
        errors: arr(errorMessages)
    }
}

export function volume(musicOn) {
    return {
        type: 'VOLUME_CONTROL',
        musicOn: musicOn
    };
}

export function helpTexts(section){
    return {
        type: 'HELP_FOR_SECTION',
        section: section
    }
}

export function slideNumber(slideNum){
    return {
        type: 'SLIDE_CHANGE',
        slide: slideNum
    }
}

export function musicChange(music) {
    return {
        type: 'MUSIC_UPDATED',
        music: music
    }
}

function arr(data) {
    return Array.isArray(data) ? data : [ data ];
}
