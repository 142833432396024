import React, { Component } from 'react';
import { connect } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from './ThemedSnackBar';

class GlobalMessages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: true
        }
        this.close = this.close.bind(this);
    }

    componentWillReceiveProps() {
        this.setState({ open: true });
    }

    close() {
        this.setState({ open: false });
    }

    render() {
        let err = (this.props.errors || []).join("\n");
        return (<Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={ err.length > 0 && this.state.open }
            onClose={ this.close }
            autoHideDuration={6000}>
            <SnackbarContent message={err}/>
        </Snackbar>);
    }
}

const mapStateToProps = (state) => {
    return {
        errors: state.errors
    };
};
export default connect(mapStateToProps)(GlobalMessages);
