import React, { Component } from 'react';
import {Editor, EditorState, RichUtils} from 'draft-js';
import 'draft-js/dist/Draft.css';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '../common/ReportButton';

class ReportEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty()
        };
        this.onChange = (editorState) => this.setState({editorState});
        this.onItalicClick = this.onItalicClick.bind(this);
        this.onBoldClick = this.onBoldClick.bind(this);
    }

    handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(this.state.editorState, command)
        if (newState) {
            this.onChange(newState);
            return 'handled';
        }
        return 'not-handled';
    }

    onItalicClick = () => {
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'ITALIC'))
    }

    onBoldClick = () => {
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'))
    }

    render() {
        return (
            <>
                <Card>
                    <CardContent>
                        <Editor editorState={this.state.editorState}
                                handleKeyCommand={this.handleKeyCommand}
                                onChange={this.onChange}/>
                    </CardContent>
                    <CardActionArea>
                        <Button onClick={ this.onItalicClick }><i>I</i></Button>
                        <Button onClick={ this.onBoldClick }><b>B</b></Button>
                    </CardActionArea>
                </Card>
            </>
        );
    }
}
export default ReportEditor;