export function miceChanged(newMice) {
    return {
        type: 'MICE_CHANGED',
        mice: newMice
    };
}

export function viewData(index, name, dataId, obese) {
    return {
        type: 'SHOW_DATA',
        data: dataId,
        name: name,
        index: index,
        obese: obese
    };
}

export function setDataType(dataType) {
    return {
        type: 'SET_DATA_TYPE',
        dataType: dataType
    };
}

export function historyItem(viewedData, dataType, similarity, environment) {
    return {
        type: 'DATA_COMPARED',
        item: { viewedData, dataType, similarity, environment }
    };
}

export function environmentalFactor(name) {
    return {
        type: 'ENVIRONMENT_CHANGED',
        environment: name.toLowerCase().replace(/\s+/g,'_')
    };
}

export function environmentalReset() {
    return {
        type: 'ENVIRONMENT_RESET'
    };
}

export function answerSupplied(questionId, answer) {
    return {
        type: 'ANSWER_SUPPLIED',
        answer: { q: questionId, a: answer }
    };
}