import {updateMiceForEnvironment, resetChildren, resetChildrenCount, ENV_DEFAULT} from '../model/Calculations';
import states from '../model/States';

export function mice(state = [], action) {
    switch (action.type) {
        case 'MICE_CHANGED':
            return action.mice;
        case 'SECTION_CHANGED':
            if (action.section === states.ADVANCED_EXPERIMENT) {
                return resetChildrenCount(state, 0);
            } else if (action.section === states.SIMPLE_EXPERIMENT) {
                    return updateMiceForEnvironment(resetChildrenCount(state, 4), ENV_DEFAULT);
            } else {
                return state;
            }
        case 'ENVIRONMENT_CHANGED':
            return updateMiceForEnvironment(resetChildren(state), action.environment);
        default:
            return state;
    }
}

export function dataType(state = [], action) {
    switch (action.type) {
        case 'SET_DATA_TYPE':
            return action.dataType;
        case 'SECTION_CHANGED':
            return "none";
        default:
            return state;
    }
}

export function viewedData(state = [], action) {
    switch (action.type) {
        case 'SET_DATA_TYPE':
        case 'ENVIRONMENT_CHANGED':
        case 'SECTION_CHANGED':
        case 'MICE_CHANGED':
            return [ null, null ];
        case 'SHOW_DATA':
            let currentData = JSON.parse(JSON.stringify(state));
            currentData[action.index] = { name: action.name, data: action.data, obese: action.obese };
            return currentData;
        default:
            return state;
    }
}

export function viewHistory(state = [], action) {
    switch (action.type){
        case 'DATA_COMPARED':
            let currentHistory = JSON.parse(JSON.stringify(state));
            currentHistory.push(action.item);
            return currentHistory;
        default:
            return state;
    }
}

export function environment(state = [], action) {
    switch (action.type) {
        case 'ENVIRONMENT_CHANGED':
            return action.environment;
        case 'ENVIRONMENT_RESET':
            return ENV_DEFAULT;
        case 'SECTION_CHANGED':
            if (action.section === states.SIMPLE_EXPERIMENT || action.section === states.ADVANCED_EXPERIMENT){
                console.log("section changed to", action.section, " => setting env to default")
                return ENV_DEFAULT;
            } else {
                return state;
            }
        default:
            return state;
    }
}

export function answers(state = [], action) {
    switch (action.type) {
        case 'ANSWER_SUPPLIED':
            let currentAnswers = JSON.parse(JSON.stringify(state));
            currentAnswers.push(action.answer);
            return currentAnswers;
        default:
            return state;
    }
}