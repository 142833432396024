import React, { Component } from 'react';
import {connect} from "react-redux";

import Mouse from './Mouse';
import {ENV_DEFAULT, ENV_CAGE_IN_DARK, ENV_PELLET_DIET, ENV_PLASTIC_BOTTLE} from '../../model/Calculations';
import states from "../../model/States";

class MouseCage extends Component {

    cageLayer(bgImgUrl, top) {
        return {
            backgroundImage: "url(images/"+bgImgUrl+")",
            backgroundSize: "300px 160px",
            width: 300,
            height: 160,
            position: "absolute",
            top: top || 185,
            left: 250+(360*(this.props.index || 0)),
        };
    }

    overIcon(env, control) {
        let result = false;
        if (env === ENV_CAGE_IN_DARK){
            result = (control) ? ["LightOn", "lights on"] : ["LightOff", "lights off"];
        }else if (env === ENV_PLASTIC_BOTTLE){
            result = (control) ? ["Glass", "glass feeding bottle"] : ["Plastic", "plastic feeding bottle"];
        }else if (env === ENV_PELLET_DIET){
            result = (control) ? ["Greens", "green diet"] : ["Pellets", "pellet diet"];
        }
        if (result) {
            result[0] = "images/GameDr_ObesityGame_AdvancedScreen_" + result[0] + ".svg";
        }
        return result;
    }

    render() {
        let mouseFamily = this.props.mouse
        let parent = (mouseFamily.parent || {})
        let child = (mouseFamily.children || {})
        let control = mouseFamily.control
        let env = (control) ? "" : this.props.environment;
        if (env === ENV_DEFAULT){ env=""; }
        let advanced = this.props.section === states.ADVANCED_EXPERIMENT
        let foodImgUrl = (env === ENV_PELLET_DIET) ? "Pellets.png" : "Greens.png";
        let drinkImgUrl = (env === ENV_PLASTIC_BOTTLE) ? "MouseCage_Experiments_PlasticBottle.svg" : "MouseCage_Experiments_GlassBottle.svg";
        let foodImgAlt = (env === ENV_PELLET_DIET) ? "Pellet food" : "Fresh food";
        let drinkImgAlt = (env === ENV_PLASTIC_BOTTLE) ? "Plastic feeding bottle" : "Glass Feeding Bottle";
        let ovIcon = this.overIcon(this.props.environment, control);

        let stylingBack = this.cageLayer("MouseCage_Full_"+parent.name+".svg");
        let stylingFront = this.cageLayer("MouseCage_"+parent.name+"_Front.svg", 182);
        let parentPos = (this.props.index === 0) ?
            [72, 58] :
            [72, 136] ;
        let childPos = (this.props.index === 0) ?
            [[72,100],[80,145],[120,90],[120,130],] :
            [[72,10],[102,50],[120,10],[120,90],] ;

        let isPregnant = this.props.mice[0].children.number === 0 && this.props.environment !== ENV_DEFAULT;

        return (
            <>
                { (env === ENV_CAGE_IN_DARK) && <div style={{
                    backgroundImage: "url(images/Shade_Back.png)",
                    backgroundSize: "285px 220px",
                    width: 285,
                    height: 220,
                    position: "absolute",
                    top: 125,
                    left: 610,
                }} />}
                <div style={ stylingBack } />
                { advanced && <img src={ "images/"+drinkImgUrl } style={{
                    position:"absolute",
                    width: 24,
                    top: 160,
                    left: (this.props.index === 0) ? 330 : 680
                }} /> }
                { (env === ENV_CAGE_IN_DARK) && <div style={{
                    backgroundImage: "url(images/MouseCage_Experiments_Shade_Front.svg)",
                    backgroundSize: "400px 220px",
                    width: 400,
                    height: 220,
                    position: "absolute",
                    top: 146,
                    left: 545,
                }} />}
                <div class='labMouseCage' style={ stylingFront } >
                <Mouse id={parent.id} parent={ true } key={parent.id} name={parent.name} data={parent} top={parentPos[0]} left={parentPos[1]}/>
                {[...Array(child.number)].map((empty, idx) => <Mouse
                     id={parent.id + "." + idx}
                     parent={ false }
                     key={parent.id + "." + idx}
                     name={parent.name + "'s child " + (idx + 1)}
                     data={child}
                     top={childPos[idx][0]}
                     left={50+childPos[idx][1]}
                />)}
                { advanced && <img src={ "images/"+foodImgUrl } style={{
                    position:"absolute",
                    width: 55,
                    top: 120,
                    left: (this.props.index === 0) ? 50 : 180
                }} /> }
                </div>
                { advanced && ovIcon[0] && <img src={ ovIcon[0] } alt={ ovIcon[1] } style={{
                    position: "absolute",
                    top: 110,
                    left: 350+(360*(this.props.index || 0)),
                    maxWidth: 64
                }}/>}
                { isPregnant && <img
                    src="images/GameDr_ObesityGame_Pregnant_Notification.png"
                    alt={"mouse is pregnant"}
                    style={{
                        position: "absolute",
                        top: 110,
                        left: 520+(360*(this.props.index || 0)),
                        maxWidth: 100
                    }}
                /> }
                { advanced && parent.pregnant && <img
                    src={ "images/AnimatedCalendar.png" }
                    alt="parent is pregnant"
                    style={{
                        position: "absolute",
                        top: 110,
                        left: 420+(360*(this.props.index || 0)),
                        maxWidth: 100
                    }}
                />}
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        section: state.section,
        environment: state.environment,
        mice: state.mice,
    };
}
export default connect(mapStateToProps)(MouseCage);
