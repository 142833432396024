import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const NavButton = withStyles({
    root: {
        backgroundColor: "#fff",
        border: "#fff 2px solid",
        borderRadius: "5em",
        color: "#205076",
        padding: "8px 15px",
        fontSize: "1em",
        marginLeft: "6px",
        marginBottom: "3px",
        marginTop: "3px",
    },
    '&:hover': {
        backgroundColor: "#88a",
        border: "#fff 2px solid",
        color: "#7DA7BE",
    },
})(Button);

export default NavButton;