const states = {
    INTRO_VIDEO: 0,
    SIMPLE_EXPERIMENT: 1,
    ADVANCED_EXPERIMENT: 2,
    WRITE_REPORT: 3
};

states.labels = {
    0: {
        self: "Introduction",
        next: "Start Experimenting",
        prev: null,
        gate: {
            next: (state) => {
                return state.slide >= 5;
            }
        }
    },
    1: {
        self: "Simple Experiment",
        next: "Continue Experimenting",
        prev: "Watch Intro",
        gate: {
            next: (state) => {
                let l = (state.answers || []).length;
                return (l>0) ? state.answers[l-1].a === "epigenetics" : false;
            }
        }
    },
    2: {
        self: "Advanced Experiment",
        next: "Write Report",
        prev: "Restart Experiment",
        gate: {
            next: (state) => {
                return (state.viewHistory || [])
                    .filter((h) => h.environment != "default")
                    .length > 0;
            }
        }
    },
    3: {
        self: "Write Report",
        next: null,
        prev: "Back to Experiment"
    },
}

states.help = {
    0: [
        {
            anchor: { el: "button.slick-next", origin:['bottom','left']},
            message:"Click arrows to advance through introduction"
        },
        {
            anchor: { el: "#nextNav", origin:['bottom','right']},
            message:"After watching introduction, proceed to experiments by clicking 'Start Experiment'"
        },
    ],
    1: [
        {
            anchor: { el: "#labToolsPicker", origin:['bottom','left']},
            message:"Select an Agouti sequence tool to start the experiment"
        },
        {
            anchor: { el: ".labMouseCage", origin:['bottom','right']},
            message:"Drag and drop offspring mice to sequence boxes to investigate"
        },
        {
            anchor: { el: ".labSimpleQuestion", origin:['bottom','left']},
            message:"Answer the question to proceed to next experiment"
        },
    ],
    2: [
        {
            anchor: { el: ".labEnvPicker", origin:['bottom','left']},
            message:"Select a variable to modify and wait for birth of offspring mice"
        },
        {
            anchor: { el: "#labToolsPicker", origin:['bottom','left']},
            message:"Then select one of the sequencing tools, and proceed as before"
        },
    ],
    3: [
        {
            anchor: { el: "#help", origin:['bottom', 'left'] },
            message: "Write your report, and then print it out"
        },
        {
            anchor: { el: ".reportPrintBtn", origin:['bottom', 'left'] },
            message: "Click here to hide instructions for a cleaner printout"
        },
    ],
    "meta": [{
        anchor: { el: "#help", origin:['bottom', 'left'] },
        message: "Click here for helpful tips about what to do next"
    }],
    "none":[]
}

states.music = {
    0: { tracks: [ "bg0intro", "bg0loop" ], loop: true },
    1: { tracks: [ "bg1loop" ], loop: true },
    2: { tracks: [ "bg2loop" ], loop: true },
    3: { tracks: [ "bg3outro" ], loop: false },
}

export default states;