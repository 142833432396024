import React, { Component } from 'react';
import {connect} from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./IntroScreen.css";
import {slideNumber} from "../../actions/navActions";

class IntroScreen extends Component {

    constructor(props) {
        super(props);
        this.currentSlide = this.currentSlide.bind(this);
    }

    currentSlide(index){
        this.props.dispatch(slideNumber(index));
    }

    render() {
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            autoplay: false,
            autoplaySpeed: 20000,
            arrows: true,
            dots: true,
            afterChange: this.currentSlide
        };
        const slides=[
            "Introduction_01_Ani.png",
            "Introduction_02.png",
            "Introduction_03.png",
            "Introduction_04_Ani.png",
            "Introduction_05.png",
            "Introduction_06_Ani.png",
        ];
        return (
            <div style={{ textAlign: "center", width:"100%" }}>
            <div style={{ width:"100%", maxWidth:"1200px", display: 'inline-block' }}>
            <Slider {...settings}>
                { slides.map((s, i) => this.renderSlide(s, i))}
            </Slider>
            </div>
            </div>
        );
    }

    renderSlide(slide, index) {
        return (<div>
            <img src={ "images/slides/"+slide } alt="TODO" width="100%"/>
        </div>);
    }
}
const mapStateToProps = (state) => {
    return {
        section: state.section,
        slide: state.slide
    };
}
export default connect(mapStateToProps)(IntroScreen);