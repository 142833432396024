import React, { Component } from 'react';
import {connect} from "react-redux";
import 'draft-js/dist/Draft.css';
import './ReportsScreen.css';

import Typography from '@material-ui/core/Typography';
import Button from '../common/ReportButton';

import { reportPreface } from '../../model/Text';
import ReportResults from './ReportResults';
import ReportEditor from './ReportEditor';

class ReportsScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            printMode: false
        };
        this.togglePrint = this.togglePrint.bind(this);
    }

    togglePrint() {
        this.setState({ printMode: !this.state.printMode });
    }

    render() {
        const showAll = !this.state.printMode;
        const imgStyle = { paddingLeft:24, maxHeight:90, maxWidth: 400 };
        return (
            <div style={{ padding: 20 }}>
                <Typography variant="h6">Results</Typography>
                { showAll && <Typography variant="body1">Results of the experiments that you have conducted in the lab are shown below.</Typography> }
                <ReportResults/>
                <Typography variant="h6">Report</Typography>
                { showAll && <Typography variant="body1">{ reportPreface }</Typography> }
                { showAll && <Typography variant="body1">Please type your report into the area below.</Typography> }
                <ReportEditor />
                <div class="reportPrintBtn">
                <Button variant="contained" onClick={ this.togglePrint }>{ (this.state.printMode) ? "Full Mode" : "Print Mode" }</Button>
                </div>
                <div>
                    <img src="images/GameDoctorLogo.jpg" style={imgStyle} />
                    <img src="images/unilogo.jpeg" style={imgStyle} />
                    <img src="images/institute.jpeg" style={imgStyle} />
                    <img src="images/wellcome2016-white-7cm.jpg" style={imgStyle} />
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        section: state.section
    };
}
export default connect(mapStateToProps)(ReportsScreen);