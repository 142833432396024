import React, { Component } from 'react';
import {connect} from "react-redux";

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from './common/NavButton';
import IconButton from '@material-ui/core/IconButton';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import HelpIcon from '@material-ui/icons/Help';

import {helpTexts, sectionChanged, volume} from '../actions/navActions';
import states from '../model/States';

class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            backMessage: null,
            nextMessage: "Start Experiment"
        };
        this.back = this.back.bind(this);
        this.next = this.next.bind(this);
        this.toggleSound = this.toggleSound.bind(this);
        this.showHelp = this.showHelp.bind(this);
    }

    componentDidMount() {
        if (this.props.section === states.INTRO_VIDEO) {
            this.props.dispatch(helpTexts("meta"));
        }
    }

    back(event) {
        this.props.dispatch(sectionChanged(this.props.section-1));
    }

    next(event) {
        this.props.dispatch(sectionChanged(this.props.section+1));
    }

    toggleSound(event) {
        this.props.dispatch(volume(!this.props.musicOn));
    }

    showHelp(event) {
        this.props.dispatch(helpTexts(this.props.section));
    }

    render() {
        const labels = states.labels[this.props.section] || {}
        const backLabel = labels.prev;
        let nextLabel = labels.next;
        const nextGateFn = (labels.gate || {}).next;
        if (nextGateFn && !nextGateFn(this.props)){
            nextLabel = false;
        }
        return (
            <Toolbar>
                <Typography variant="h6" color="inherit" noWrap>{ labels.self }</Typography>

                { backLabel && <span id='backNav'><Button variant="contained" onClick={ this.back }>{ backLabel }</Button></span> }

                <span style={{ marginLeft: "auto" }}>
                { nextLabel && <span id='nextNav'><Button variant="contained" onClick={ this.next }>{ nextLabel }</Button></span> }
                <IconButton onClick={ this.toggleSound }>
                    { this.props.musicOn && <VolumeOffIcon/> }
                    { !this.props.musicOn && <VolumeDownIcon/> }
                </IconButton >
                <span id="help">
                    <IconButton onClick={ this.showHelp }><HelpIcon/></IconButton>
                </span>
                </span>
                { this.props.section > 0 && this.props.section < 3 && <span style={{ width: 400}}/> }

            </Toolbar>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        section: state.section,
        answers: state.answers,
        musicOn: state.musicOn,
        slide: state.slide,
        viewHistory: state.viewHistory,
    };
}
export default connect(mapStateToProps)(Navigation);
