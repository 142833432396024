import React, { Component } from 'react';
import {connect} from "react-redux";

import Typography from '@material-ui/core/Typography';
import Button from '../common/SideBarButton';

import {answerSupplied, setDataType} from '../../actions/experimentActions';
import { errors } from '../../actions/navActions';
import states from '../../model/States';
import {
    sidebarTextSimple,
    sidebarTextAdvanced,
    questionSimple,
    replyToChoosingDnaMutations,
    replyToChoosingEpigeneticModifications
} from '../../model/Text';
import States from '../../model/States';

import Environments from "./Environments";

class ExperimentSideBar extends Component {

    constructor(props) {
        super(props);
        this.toggleGenesView = this.toggleGenesView.bind(this);
        this.toggleEpiView = this.toggleEpiView.bind(this);
        this.selectDNAMutations = this.selectDNAMutations.bind(this);
        this.selectEpigeneticModifications = this.selectEpigeneticModifications.bind(this);
    }

    toggleGenesView() {
        this.toggle("genes");
    }

    toggleEpiView() {
        this.toggle("epigenetics");
    }

    toggle(dt) {
        var newDataType = (this.props.dataType === dt) ? null : dt;
        this.props.dispatch(setDataType(newDataType));
    }

    selectDNAMutations() {
        this.props.dispatch(errors(replyToChoosingDnaMutations));
        this.props.dispatch(answerSupplied("LO1", "genes"));
    }

    selectEpigeneticModifications() {
        this.props.dispatch(errors(replyToChoosingEpigeneticModifications));
        this.props.dispatch(answerSupplied("LO1", "epigenetics"));
    }

    render() {
        let boilerplateTexts = ((this.props.section === States.ADVANCED_EXPERIMENT) ?
            sidebarTextAdvanced :
            sidebarTextSimple).split(/\n+/);
        let simpleQuestionPresent = (this.props.section === states.SIMPLE_EXPERIMENT && this.props.viewHistory.length > 0);
        let activeTexts = boilerplateTexts
            .filter(t => t.length > 0)
        return (
            <div style={{ maxWidth: 400, padding: 12 }}>
                {activeTexts.map((t,i) => {
                    let join = (i === activeTexts.length-1) ? null : <hr/>;
                    return (<><Typography variant="body1">{ t }</Typography>{ join }</>);
                })}
                { this.props.section === states.ADVANCED_EXPERIMENT && <><Environments /><hr/></> }
                <span id="labToolsPicker">
                <Button variant='contained' onClick={ () => this.toggleGenesView() }>Compare Agouti Gene Sequence</Button>
                <Button variant='contained' onClick={ () => this.toggleEpiView() }>Compare Agouti Epigenetic Sequence</Button>
                </span>
                { simpleQuestionPresent && this.renderFollowUp() }
            </div>
        );

    }

    renderFollowUp() {
        return (
            <div class='labSimpleQuestion'>
                <Typography variant="body1">{ questionSimple }</Typography>
                <Button variant='contained' onClick={ () => this.selectDNAMutations() }>DNA Mutations</Button>
                <Button variant='contained' onClick={ () => this.selectEpigeneticModifications() }>Epigenetic Modifications</Button>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        section: state.section,
        dataType: state.dataType,
        viewHistory: state.viewHistory,
        mice: state.mice
    };
}
export default connect(mapStateToProps)(ExperimentSideBar);
