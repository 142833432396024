import React, { Component } from 'react';
import Popover from '../common/ThemedHelpMessage';
import Typography from '@material-ui/core/Typography';
import { helpTexts } from '../../actions/navActions';

import {connect} from "react-redux";

class HelpText extends Component {

    constructor(props){
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.props.dispatch(helpTexts("none"));
    }

    render() {
        let helps = (this.props.help || []);
        return (<>
        { helps.map((h, i) => {
            const aEl = document.querySelector(h.anchor.el);
            const o = h.anchor.origin;
            return (aEl) ?
                <Popover
                    open={true}
                    anchorEl={ aEl }
                    anchorOrigin={{ vertical: o[0], horizontal: o[1] }}
                    onClose={ this.handleClose }
                >
                    { helps.length > 1 && <span style={{
                        color:"#205076",
                        fontSize:"2em",
                        fontWeight:"bold",
                        cssFloat:"left",
                        paddingRight:"11px" }}>{i+1}</span> }
                    <Typography variant="body1">{ h.message }</Typography>
                </Popover> :
                null;
        }).filter((pop) => pop)}
        </>);
    }
}
const mapStateToProps = (state) => {
    return {
        help: state.help,
    };
}
export default connect(mapStateToProps)(HelpText);
