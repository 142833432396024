import React, { Component } from 'react';
import {connect} from "react-redux";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class ReportResults extends Component {

    renderSubject(subject) {
        let imgSrc = (subject.obese) ? "MousePup_Obese" : "MousePup_Normal";
        imgSrc="images/GameDr_ObesityGame_"+imgSrc+".png";
        return (<>
            <img src={ imgSrc } alt="mouse pup" style={{ height: "24px", display: "block" }}/>
            <span>{ subject.name+" ("+subject.data+")" }</span>
        </>);
    }

    render() {
        return (
            <Table>
                <TableHead>
                    <TableCell/>
                    <TableCell>Subject 1</TableCell>
                    <TableCell>Subject 2</TableCell>
                    <TableCell>Environment</TableCell>
                    <TableCell>Data Type</TableCell>
                    <TableCell>Comparison</TableCell>
                </TableHead>
                <TableBody>
                    { this.props.viewHistory.map((item, index) => (
                        <TableRow key={ "hist_"+index }>
                            <TableCell>{ index }</TableCell>
                            <TableCell>{ this.renderSubject(item.viewedData[0]) }</TableCell>
                            <TableCell>{ this.renderSubject(item.viewedData[1]) }</TableCell>
                            <TableCell>{ item.environment }</TableCell>
                            <TableCell>{ item.dataType }</TableCell>
                            <TableCell>{ item.similarity }</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        viewHistory: state.viewHistory
    };
}
export default connect(mapStateToProps)(ReportResults);