export const NORMAL_GENES = "normal";
export const METHYLATED_EPI = "methylated DNA";
export const UNMETHYLATED_EPI = "unmethylated DNA";
export const NO_EFFECT = "no effect";
export const ENV_DEFAULT = "default";
export const ENV_PELLET_DIET = "diet";
export const ENV_PLASTIC_BOTTLE = "water_bottle";
export const ENV_CAGE_IN_DARK = "cage_in_dark";

let env = {};
env[ENV_DEFAULT] = UNMETHYLATED_EPI;  //aka simple experiment
env[ENV_PELLET_DIET] = UNMETHYLATED_EPI;
env[ENV_PLASTIC_BOTTLE] = UNMETHYLATED_EPI;
env[ENV_CAGE_IN_DARK] = NO_EFFECT;
export const ENVIRONMENTAL_EFFECT_ON_EPIGENETICS = env;

export const DIFF_IDENTICAL = "identical";
export const DIFF_DIFFERENT = "different";

export function diff(viewedData) {
    viewedData = viewedData || [];
    var result = null;
    if (viewedData.length === 2) {
        let val1 = (viewedData[0] || {}).data;
        let val2 = (viewedData[1] || {}).data;
        result = (val1 && val2) ?
            (val1 === val2) ?
                DIFF_IDENTICAL :
                DIFF_DIFFERENT+" ("+val1+"/"+val2+")" :
            null;
    }
    return result;
}

export function toKey(str) {
    return str.toLowerCase().replace(/\s+/g, "_");
}

export function mouseFamily(id, name, isControl, numberOfChildren, methylated) {
    let epi = (methylated) ? METHYLATED_EPI : UNMETHYLATED_EPI;
    return {
        control: isControl,
        parent: {
            id: id,
            name: name,
            genes: NORMAL_GENES,
            epigenetics: METHYLATED_EPI,
            pregnant: false
        },
        children: {
            number: numberOfChildren,
            genes: NORMAL_GENES,
            epigenetics: epi
        }
    };
}

export function resetChildrenCount(mice, count, parentPregnant) {
    let currentMice = JSON.parse(JSON.stringify(mice));
    currentMice
        .map((m) => {
            m.children.number = count;
            m.parent.pregnant = (count > 0) ? false : parentPregnant;
            return m;
        });
    return currentMice;
}

export function resetChildren(mice) {
    let currentMice = JSON.parse(JSON.stringify(mice));
    currentMice
        .filter((m) => !m.control)
        .map((m) => {
            m.children.epigenetics = m.parent.epigenetics;
            return m;
        });
    return currentMice;
}

export function updateMiceForEnvironment(mice, env) {
    let currentMice = JSON.parse(JSON.stringify(mice));
    let key = toKey(env);
    let effect = ENVIRONMENTAL_EFFECT_ON_EPIGENETICS[key];
    if (effect && effect !== NO_EFFECT) {
        currentMice
            .filter((m) => !m.control)
            .map((m) => {
                m.children.epigenetics = effect;
                return m;
            });
    }
    currentMice.map((m) => {
        m.parent.pregnant = true;
        return m;
    });
    return currentMice;

}

