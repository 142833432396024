import { combineReducers } from 'redux';
import { section, errors, backgroundMusic, musicOn, help, slide } from './navReducers';
import { mice, viewedData, dataType, viewHistory, environment, answers } from './experimentReducers';

export default combineReducers({
    section,
    errors,
    backgroundMusic,
    musicOn,
    help,
    slide,
    mice,
    viewedData,
    dataType,
    viewHistory,
    environment,
    answers
});
