import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import App from './App';
import * as serviceWorker from './serviceWorker';

import states from './model/States';
import { mouseFamily } from './model/Calculations';

let iniMusic = { tracks: [ "Lab Scene Full" ], loop: true };

const initialState = {
    section: states.INTRO_VIDEO,
    mice:[
        mouseFamily("m01", "Tasha", true, 4, true),
        mouseFamily("m02", "Abby", false, 4, false) ],
    viewedData: [ null, null ],
    dataType: "none",
    environment: "default",
    viewHistory: [],
    errors: [],
    answers: [],
    backgroundMusic: {
        tracks: iniMusic.tracks,
        loop: iniMusic.loop,
        current: 0,
    },
    musicOn: localStorage.getItem("VOLUME_CONTROL") || true,
    help:[],
    slide: 0
};

const store = configureStore(initialState);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
