import React, { Component } from 'react';
import { connect } from 'react-redux';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import './App.css';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';

import Navigation from './components/Navigation';
import IntroScreen from './components/intro/IntroScreen';
import ExperimentScreen from './components/lab/ExperimentScreen';
import ReportsScreen from './components/report/ReportsScreen';

import ExperimentSideBar from './components/lab/ExperimentSideBar';
import GlobalMessages from "./components/common/GlobalMessages";
import HelpText from "./components/common/HelpText";
import BackgroundMusic from './components/common/BackgroundMusic';

import states from './model/States';

const appBarHeight = 64;
const styles = {
    root: {
        display: 'flex',
    },
    appBar: {
        bottom: 0,
        backgroundColor: "#7DA7BE",
        maxHeight: appBarHeight
    },
    paper: {
        paddingTop: appBarHeight,
        paddingBottom: 20,
        height: `calc(100% - ${appBarHeight}px)`
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        flexShrink: 0
    },
    infoDrawer: {
        paddingTop: 42
    },
};

const ThemedAppBar = withStyles({ root: styles.appBar })(AppBar);

class App extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let section = this.props.section;

        let bgCol = (section === states.WRITE_REPORT) ? "#fff" : "#7DA7BE";
        document.body.style.backgroundColor = bgCol;

        return (
            <React.Fragment>

                <CssBaseline/>

                <ThemedAppBar position="fixed" classes={ styles.appBar }>
                    <Navigation />
                </ThemedAppBar>

                <GlobalMessages/>

                { section > states.INTRO_VIDEO && section < states.WRITE_REPORT &&
                <Drawer
                    variant="permanent"
                    anchor="right"
                    classes={ styles.drawer }
                    open={true}
                >
                    <ExperimentSideBar />
                </Drawer> }

                <div style={ styles.paper } >
                    { section === states.INTRO_VIDEO && <IntroScreen/> }
                    { section === states.SIMPLE_EXPERIMENT && <ExperimentScreen/> }
                    { section === states.ADVANCED_EXPERIMENT && <ExperimentScreen advanced={ true }/> }
                    { section === states.WRITE_REPORT && <ReportsScreen/> }
                </div>

                <BackgroundMusic />

                <HelpText />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        section: state.section,
    };
}
export default DragDropContext(HTML5Backend)(connect(mapStateToProps)(withStyles(styles, { withTheme: true })(App)));
