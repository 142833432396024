import React, {Component} from "react";
import {connect} from "react-redux";
import {DropTarget} from "react-dnd";

class DataVisRow extends Component {

    render() {
        let allData = this.props.viewedData || [];
        let index = this.props.index;
        let datum = (index>=0 && index<2 && allData.length === 2) ? allData[index] : null;
        let isGenes = this.props.dataType === "genes";
        let isObese = (datum || {}).obese;
        let imgSrc = (datum == null) ? ((isGenes) ? "DNA_OFF" : "Epi_OFF") :
            (isGenes) ? "DNA_ON" :
            (isObese) ? "Epi_On_Unmeth" : "Epi_On_Meth";
        imgSrc="images/GameDr_ObesityGame_SequenceBackground_"+imgSrc+".png";
        let imgAlt = (datum == null) ? "No data" :
            (isGenes) ? "Genetic Trace, showing no anomalies" :
            (isObese) ? "Epigenetic Trace showing Unmethylated groups" : "Epigenetic Trace showing Methylated groups";
        let mouseSrc = (isObese) ? "Obese" : "Normal";
        let mouseAlt = mouseSrc+" Mouse Pup";
        mouseSrc = "images/GameDr_ObesityGame_MousePup_"+mouseSrc+".png";
        return this.props.connectDropTarget(<div>
            <img src={ imgSrc } alt={ imgAlt }/>
            { datum && <img src={ mouseSrc } alt={ mouseAlt } style={{
                position: "relative", left: -660, top: -30
            }} /> }
        </div>);
    }
}

const dataVisRowTarget = {
    drop(props, monitor, component) {
        let payload = monitor.getItem();
        let mouse = payload.data;
        mouse.name = payload.name;
        if (props.endDrop){
            props.endDrop(mouse, props.index);
        }
        return mouse;
    }
}
function collect(connector, monitor) {
    return {
        connectDropTarget: connector.dropTarget(),
        isOver: monitor.isOver()
    };
}
const mapStateToProps = (state) => {
    return {
        viewedData: state.viewedData,
        dataType: state.dataType
    };
};
export default DropTarget("MOUSE", dataVisRowTarget, collect)(connect(mapStateToProps)(DataVisRow));
