import React from 'react';
import { DragSource } from 'react-dnd'

import { UNMETHYLATED_EPI } from '../../model/Calculations';

function Mouse(props) {
    let isParent = props.parent;
    return (isParent) ? mouseImg(props) : props.connectDragSource(mouseImg(props));
}

function mouseImg(props){
    let isObese = props.data.epigenetics === UNMETHYLATED_EPI;
    let isParent = props.parent;
    let imgSrc = (isParent) ? "AdultMouse_" + props.name :
        (isObese) ? "MousePup_Obese" : "MousePup_Normal";
    imgSrc="images/GameDr_ObesityGame_"+imgSrc+".png";
    let width = (isParent) ? "49%" : "24%";
    let imgSize = (isParent) ? 90: (isObese) ? 40 : 52;
    let styling = { width: width, position: "absolute", top: props.top || 0, left: props.left || 0 };
    return (
        <span style={ styling }>
            <img
                src={ imgSrc }
                alt={ (isObese) ? "Obese Mouse" : "Non-obese Mouse" }
                width={ imgSize }
            />
        </span>
    );
}

const mouseItemSource = {
    beginDrag(props) {
        let dndObj = {
            id: props.id,
            name: props.name,
            data: props.data

        }
        return dndObj;
    }
}
function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
}
export default DragSource("MOUSE", mouseItemSource, collect)(Mouse)