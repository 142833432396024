import React, { Component } from 'react';
import {connect} from "react-redux";

import Button from '../common/SideBarButton';
import Typography from '@material-ui/core/Typography';

import {environmentalFactor, environmentalReset, miceChanged} from '../../actions/experimentActions';
import { environmentText } from '../../model/Text';
import {ENV_DEFAULT, resetChildrenCount, toKey} from '../../model/Calculations';

class Environments extends Component {

    constructor(props) {
        super(props);
        this.timeout = null;
        this.adjustEnvironment = this.adjustEnvironment.bind(this);
        this.birthInEnvironment = this.birthInEnvironment.bind(this);
    }

    componentWillUnmount() {
        if (this.timeout){
            clearTimeout(this.timeout);
        }
    }

    adjustEnvironment(env) {
        this.props.dispatch(environmentalFactor(env));
        this.timeout = setTimeout(this.birthInEnvironment, 3000);
    }

    birthInEnvironment(){
        this.props.dispatch(miceChanged(resetChildrenCount(this.props.mice, 4)));
    }

    resetVariableMouse() {
        this.props.dispatch(environmentalReset());
        this.props.dispatch(miceChanged(resetChildrenCount(this.props.mice, 0, false)));
    }


    render() {
        console.log("env render props", this.props);
           let key = toKey(this.props.environment);
           let envText = environmentText[key] || "";
           let needsReset = this.props.mice[0].children.number > 0;
           let envInProgress = !needsReset && this.props.environment !== ENV_DEFAULT;
           return (
               <div class="labEnvPicker">
                   { !envInProgress && !needsReset && this.renderButton("Diet") }
                   { !envInProgress && !needsReset && this.renderButton("Water bottle") }
                   { !envInProgress && !needsReset && this.renderButton("Cage in dark") }
                   { envInProgress && false && <img src="images/GameDr_ObesityGame_Pregnant_Notification.png" alt="mice are pregnant" style={{ maxWidth: 120, textAlign:"center" }} /> }
                   { needsReset && <Button variant='contained' size='large' onClick={ () => this.resetVariableMouse() }>Investigate Another Variable</Button> }
                   { envText.length > 0 &&
                    <>
                        <Typography variant="body1">{envText}</Typography>
                    </>
                   }
               </div>
           );
    }

    renderButton(label) {
        let selected = (label === this.props.environment);
        let color = (selected) ? 'primary' : 'default';
        let imgSrc = {
            "Diet" : "Food",
            "Water bottle" : "Bottle",
            "Cage in dark" : "Light"
        }[label];
        imgSrc = "images/GameDr_ObesityGame_AdvancedScreen_" + imgSrc + ".svg";
        return (
            <img src={ imgSrc } alt={ label } onClick={ () => this.adjustEnvironment(label) } style={{ paddingLeft: 24, maxWidth: 120 }}/>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        environment: state.environment,
        mice: state.mice
    };
}
export default connect(mapStateToProps)(Environments);
