import React, { Component } from 'react';
import {connect} from "react-redux";

import states from '../../model/States';
import MouseCage from './MouseCage';
import DataVis from './DataVis';

import { viewData } from '../../actions/experimentActions';
import {UNMETHYLATED_EPI} from "../../model/Calculations";

const GRAPHICS_WIDTH = 1240;
const SIDEBAR_WIDTH = 400;

class ExperimentScreen extends Component {

    constructor(props){
        super(props);
        this.state = {
            scale: this.calcScale()
        };
        this.endDrop = this.endDrop.bind(this);
        window.addEventListener('resize', () => {
            this.setState({ scale: this.calcScale() });
        });
    }

    calcScale() {
        let ww = window.innerWidth;
        return ((ww - SIDEBAR_WIDTH)/GRAPHICS_WIDTH);
    }

    endDrop(mouse, index){
        this.props.dispatch(viewData(index, mouse.name, mouse[this.props.dataType], mouse.epigenetics === UNMETHYLATED_EPI ));
    }

    render() {
        const i = (this.props.advanced) ? 2 : 1;
        const labels = states.labels[i] || {}

        return (<div style={{
            width: "100%",
            backgroundImage: "url(images/MouseCage_Experiments_BGb.png)",
            backgroundSize: "1366px 768px",
            minHeight: 768,
            backgroundRepeat: "repeat-x",
            transform: "scale("+this.state.scale+")",
            transformOrigin: "top left",
        }}>
            { (this.props.mice || []).map((mouse, index) => <MouseCage key={ mouse.parent.id } index={ index } mouse = { mouse } />)}
            { this.props.dataType !== "none" && <DataVis endDrop={ this.endDrop }/> }
        </div>)
    }
}
const mapStateToProps = (state) => {
    return {
        section: state.section,
        mice: state.mice,
        dataType: state.dataType
    };
}
export default connect(mapStateToProps)(ExperimentScreen);