import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const SideBarButton = withStyles({
    root: {
        backgroundColor: "#7DA7BE",
        border: "#FFFFFF 2px solid",
        borderRadius: "5em",
        color: "#FFFFFF",
        padding: "8px 15px",
        fontSize: "1em",
        marginLeft: "6px",
        marginBottom: "3px",
        marginTop: "3px",
        width: "95%",
    },
    '&:hover': {
        backgroundColor: "#FFFFFF",
        border: "#7DA7BE 2px solid",
        color: "#7DA7BE",
    },
})(Button);

export default SideBarButton;