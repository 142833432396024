import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from "@material-ui/core/SnackbarContent";

const ThemedSnackBar = withStyles({
    root: {
        color: "white",
        fontSize: "1.5em",
        maxWidth: 400
    },
})(SnackbarContent);

export default ThemedSnackBar;