import React from 'react';

export const sidebarTextSimple = "From your reading of peer-reviewed science articles, you are aware of a gene named ‘Agouti’ that influences both weight and fur colour in mice.\n\nYou decide to investigate further. What will you do next?\n"

export const sidebarTextAdvanced = "As you'll remember, Abby and Tasha were raised in different conditions. Now it's time for you to investigate each condition individually to understand exactly how changes in the environment can affect the phenotype through epigenetics.\n\n"
    +"Further reading of peer-reviewed science articles, suggests that the maternal environment can affect epigenetic modifications and gene expression in the offspring. Abby and Tasha have just become pregnant again, so you can investigate the effect of different environmental factors.\n\n"
    +"Select which environmental variable to investigate further and then analyse your results.\n";

export const questionSimple = "What is causing the difference in phenotype?\n";

export const environmentText = {
    "diet":"Previous research has suggested that feeding mice a normal diet of green vegetables keeps them a normal weight, whilst feeding mice a diet of pellets can increase the likelihood of obesity.\n",
    "water_bottle":"Previous research has suggested that providing mice with water from a glass bottle keeps them a normal weight, whilst providing mice with water from a plastic bottle (containing bisphenol A) can increase the likelihood of obesity.\n",
    "cage_in_dark":"There is no previous research that has examined a potential link between levels of light and an increased likelihood of obesity. If you can establish a correlation then this could be ground-breaking!\n"
}

export const reportPreface = (<>
<p>Can you now explain to ‘The Principal Investigator’ what caused the incredible difference in phenotype of the mice born to Abby and Tasha, mice from the same litter, mated with the same male?</p>
<p>Your report should include:</p>
<ul>
    <li>the independent and dependent variables</li>
    <li>observations of phenotype, DNA sequence and epigenetic modification</li>
    <li>interpretation of DNA sequence and epigenetic modification data</li>
</ul>
<p>Make sure you use scientific language in your answers, including words like <i>DNA sequence, epigenetic modification, DNA methylation, phenotype, genotype, transcription</i> and <i>environment</i></p></>);

export const replyToChoosingEpigeneticModifications = "You are absolutely correct! It is methylation of the Agouti gene that causes the differences in phenotype in the mice";
export const replyToChoosingDnaMutations = "Try clicking on the 'compare Agouti gene sequence' button and look again at the sequence similarity";
